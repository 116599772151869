import React from 'react';
import {RichText} from 'prismic-reactjs';
import Box from '@material-ui/core/Box';
import SEO from "../components/utility/SEO";
import GridContainer from "../components/GridContainer";
import Typography from "@material-ui/core/Typography";
import {graphql} from "gatsby";

export default function PrivacyPolicy({data}) {

  if (!data || !data.prismicPrivacyPolicyPage) return '';
  const { data: pageData } = data.prismicPrivacyPolicyPage;

  return (
    <>
      <SEO title="Privacy Policy"/>
      <Box>
        <GridContainer gridItemProps={{xs: 11, md: 8}}>
          <div style={{width: '100%', marginTop: '60px', marginBottom: '60px'}}>
            <Typography variant={'h2'}>{pageData.title}</Typography>
            <RichText render={pageData.content.raw}/>
          </div>
        </GridContainer>
      </Box>
    </>
  );
}

export const privacyPolicyPageQuery = graphql`
    query PrivacyPolicyPage($id: String!) {
        prismicPrivacyPolicyPage(id: {eq: $id}) {
            data {
                title
                content {
                    raw
                }
            }
        }
    }
`;
